.author {
  display: flex;
  align-items: center;

  &.fancy {
    gap: var(--author-fancy-gap);

    .title {
      opacity: 0.3;
    }

    .images {
      display: flex;
    }

    .imageContainer {
      border: var(--author-fance-img-border) solid var(--clr-white);
      width: var(--author-fancy-img-size);
      height: var(--author-fancy-img-size);
      position: relative;
      flex-shrink: 0;
    }
  }

  &.simple {
    gap: var(--author-simple-gap);

    .subtitle {
      opacity: 0.4;
    }
  }

  .imageContainer {
    width: var(--author-simple-img-size);
    height: var(--author-simple-img-size);
    position: relative;
    flex-shrink: 0;
  }
}

.imageContainer {
  border-radius: 50%;
  overflow: hidden;

  & + & {
    margin-left: -20%;
  }
}

.image {
  object-fit: cover;
}
