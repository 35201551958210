.heading {
  color: inherit;
  white-space: pre-wrap;

  em {
    color: var(--clr-primary);
    font-style: normal;
  }

  span {
    color: rgba(30, 30, 30, 0.4);
  }

  &.regular,
  &.compact.h2,
  &.sub.h2 {
    font-family: var(--neue-plak-font);
    font-weight: 400;
    line-height: 0.9;
    text-transform: uppercase;
  }

  &.alternative,
  &.compact.h3,
  &.sub.h3 {
    font-family: var(--inter-font);
    font-weight: 700;
    line-height: 1.5;
  }

  &.h2 {
    &.regular {
      font-size: var(--h2-reg-size);
    }
    &.alternative {
      font-size: var(--h2-alt-size);
    }
    &.compact {
      font-size: var(--h2-comp-size);
    }
    &.sub {
      font-size: var(--h2-sub-size);
    }
  }

  &.h3 {
    &.regular {
      font-size: var(--h3-reg-size);
    }
    &.compact {
      font-size: var(--h3-comp-size);
    }
    &.sub {
      font-size: var(--h3-sub-size);
    }
  }
}
